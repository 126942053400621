.tooltipmsgsSide {
    position: relative;
    display: inline-block;
  }
  
  .tooltipmsgsSide .tooltiptextmsgsSide {
    visibility: hidden;
    width: 120px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltipmsgsSide:hover .tooltiptextmsgsSide {
    visibility: visible;
    word-break:break-all;
    border: 1px solid;
    width: 215px;

    /* inline-size: 300px;
    overflow-wrap: break-word;
    border: 1px solid;
    margin-top: -111px;
    margin-left: -413px; */
  }