.half1 {
  margin-left: 3% !important;
  margin-top: 2% !important;
  font-size: initial !important;
}
.img {
  width: 50%;
}
.half1 label {
  display: block;
}
