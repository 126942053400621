.choices {
  margin-top: 20px;
  padding: 5px;
}
.addOption {
  background-color: #4b9303 !important;
  color: #fff !important;
  margin-left: 5px !important;
  margin-top: 9px !important;
}
.queation_and_type {
  display: flex;
}
.questions {
  width: 68%;
}
.type {
  width: 19%;
  margin-top: -5px;
  margin-left: 2%;
}
.remove {
  width: 10%;
  margin-top: -5px;
  margin-left: 2%;
}
.removeqn {
  color: rgb(255 141 0);
  margin-top: 14px;
  margin-left: 9px;
  font-size: 40px !important;
  cursor: pointer;
}
.addSubCatBtn {
  position: absolute !important;
  margin-left: 73% !important;
}
