.item {
  padding: 10px;
}
.half {
  width: 50%;
  margin-left: 3%;
  font-size: initial;
}
.half1 {
  margin-left: 3% !important;
  margin-top: 2% !important;
  font-size: initial !important;
}
.half1 label {
  display: block;
}
.half label {
  width: 150px;
}
.edit {
  background-color: #667eea !important;
  color: #ffffff !important;
}
.cancel {
  background-color: #dd3f3f !important;
  color: #ffffff !important;
  margin-left: 3px !important;
}
.leftmenu ul li ul li {
  background-color: #e4e4e4;
  margin-top: 1px;
  margin-right: 20px;
  line-height: 30px;
  border-radius: 7px;
  color: #667eea;
  padding-left: 20px;
}
.leftmenu ul li ul .active {
  background-color: #667eea;
  margin-top: 1px;
  margin-right: 20px;
  line-height: 30px;
  border-radius: 7px;
  color: #ffffff;
  padding-left: 20px;
}
li .in {
  margin-left: 30px;
  line-height: 25px;
}
ul .main {
  margin-left: 10px;
}
.responsiveTable td {
  border: #9c9cb0 solid;
  height: 35px;
}
.responsiveTable {
  width: 90% !important;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
}
.viewbtn {
  height: 20px;
  background-color: #00a000 !important;
  color: #ffffff !important;
}
button {
  text-transform: capitalize !important;
  font-size: initial;
}
.link {
  color: #667eea !important;
  cursor: pointer;
}
.leftmenu {
  margin: 6% !important;
}
.switchUser {
  float: right;
  margin-right: 5%;
}
.switchUser b {
  font-weight: 800;
  font-size: medium;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.editform {
  /* width:"80%",
     height:"100%",
     padding:"20px",
     top:5, */
  margin-left: 60;
  position: 'relative';
  background: '#ffffff';
  padding: '20px';
}
@media only screen and (max-width: 960px) {
  .editform {
    /* width:"80%",
       height:"100%",
       padding:"20px",
       top:5, */
    left: 0 !important;
    position: 'relative';
    background: '#ffffff';
    padding: '20px';
  }
}

@media only screen and (max-width: 555px) {
  .switchUser {
    float: left !important;
    margin: 11px 0px 0px 25px !important;
  }
}
